
const menuItems = {
    items: [
        {
            id: 'navigation',
             type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/app/dashboard/default',
                    icon: 'feather icon-home'
                },
                {
                    id: 'chatBot',
                    title: 'ChatBots',
                    type: 'item',
                    url: '/chatbot/index',
                    icon: 'feather icon-home'
                },
                {
                    id: 'visitors',
                    title: 'Visitors',
                    type: 'item',
                    url: '/visitor/list',
                    icon: 'feather icon-user'
                },
                {
                    id: 'contact',
                    title: 'Contacts',
                    type: 'item',
                    url: '/contact/list',
                    icon: 'feather icon-users'
                },
                {
                    id: 'analytics',
                    title: 'Analytics',
                    type: 'item',
                    url: '/app/dashboard/default',
                    icon: 'feather icon-charts'
                }
            ]
        },
        {
            id: 'ui-element',
            title: '',
            type: 'group',
            icon: 'icon-ui',
            children: [
                {
                    id: 'basic',
                    title: 'Settings',
                    type: 'collapse',
                    icon: 'feather icon-box',
                    children: [
                        {
                            id: 'department',
                            title: 'Department',
                            type: 'item',
                            url: '/department/list'
                        },
                        {
                            id: 'contactProperty',
                            title: 'Contact Property',
                            type: 'item',
                            url: '/contactProperty/list'
                        },
                        {
                            id: 'quickResponse',
                            title: 'Quick Response',
                            type: 'item',
                            url: '/quickResponse/list'
                        },
                        {
                            id: 'project',
                            title: 'Project',
                            type: 'item',
                            url: '/project/edit'
                        },
                        //{
                        //    id: 'collapse',
                        //    title: 'Collapse',
                        //    type: 'item',
                        //    url: '/basic/collapse'
                        //},
                        //{
                        //    id: 'tabs-pills',
                        //    title: 'Tabs & Pills',
                        //    type: 'item',
                        //    url: '/tables/bootstrap'
                        //},
                        //{
                        //    id: 'typography',
                        //    title: 'Typography',
                        //    type: 'item',
                        //    url: '/basic/button'
                        //}
                    ]
                }
            ]
        }
        //{
        //    id: 'ui-forms',
        //    title: 'Forms & Tables',
        //    type: 'group',
        //    icon: 'icon-group',
        //    children: [
        //        {
        //            id: 'forms',
        //            title: 'Forms',
        //            type: 'item',
        //            url: '/forms/form-basic',
        //            icon: 'feather icon-file-text'
        //        },
        //        {
        //            id: 'bootstrap',
        //            title: 'Bootstrap Table',
        //            type: 'item',
        //            url: '/tables/bootstrap',
        //            icon: 'feather icon-server'
        //        }
        //    ]
        //},
        //{
        //    id: 'chart-maps',
        //    title: 'Chart & Maps',
        //    type: 'group',
        //    icon: 'icon-charts',
        //    children: [
        //        {
        //            id: 'charts',
        //            title: 'Charts',
        //            type: 'item',
        //            url: '/charts/nvd3',
        //            icon: 'feather icon-pie-chart'
        //        },
        //        {
        //            id: 'maps',
        //            title: 'Maps',
        //            type: 'item',
        //            url: '/maps/google-map',
        //            icon: 'feather icon-map'
        //        }
        //    ]
        //},
        //{
        //    id: 'pages',
        //    title: 'Pages',
        //    type: 'group',
        //    icon: 'icon-pages',
        //    children: [
        //        {
        //            id: 'auth',
        //            title: 'Authentication',
        //            type: 'collapse',
        //            icon: 'feather icon-lock',
        //            children: [
        //                {
        //                    id: 'signup-2',
        //                    title: 'Sign UP (template)',
        //                    type: 'item',
        //                    url: '/auth/signup-2',
        //                    target: true,
        //                    breadcrumbs: false
        //                },
        //                {
        //                    id: 'signin-2',
        //                    title: 'Sign IN (template)',
        //                    type: 'item',
        //                    url: '/auth/signin-2',
        //                    target: true,
        //                    breadcrumbs: false
        //                }
        //            ]
        //        },
        //        {
        //            id: 'sample-page',
        //            title: 'Sample Page',
        //            type: 'item',
        //            url: '/sample-page',
        //            classes: 'nav-item',
        //            icon: 'feather icon-sidebar'
        //        }
        //    ]
        //},
        //{
        //    id: 'resources',
        //    title: 'Resources',
        //    type: 'group',
        //    icon: 'icon-pages',
        //    children: [
        //        {
        //            id: 'product-page',
        //            title: 'Download Product',
        //            type: 'item',
        //            url: 'https://appseed.us/product/react-node-js-datta-able',
        //            classes: 'nav-item',
        //            icon: 'feather icon-download',
        //            target: true,
        //            external: true
        //        },
        //        {
        //            id: 'support',
        //            title: 'Get Support',
        //            type: 'item',
        //            icon: 'feather icon-help-circle',
        //            classes: 'nav-item',
        //            url: 'https://appseed.us',
        //            target: true,
        //            external: true
        //        }
        //    ]
        //}        
    ]
};

export default menuItems;
